import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";
import { normalizeWheel } from "../utils/WheelUtils";

export default class HomeController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'HomeController->constructor()' );

        this.initDomElements();
        this.addEventListeners();
        this.initRevealTl();

        this.ready();
    }


    initDomElements() {
        var $mains = document.body.querySelectorAll( 'main' );
        this.$page = ( $mains.length > 1 ) ? $mains[1] : $mains[0];
    }

    addEventListeners() {

    }

    initRevealTl() {
        // Els
        // var animatedTextIn = this.$page.querySelectorAll( '.template-home__header .animated-text' );
        // var animatedMediaIn = this.$page.querySelectorAll( '.template-home__header .animated-media' );

        this.revealTl = new TimelineLite( { paused : true } );

        // this.revealTl
        //     .staggerFromTo( animatedTextIn, 0.8, { y: 15, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: Power2.easeOut }, 0.15, 'start')
        //     .fromTo( animatedMediaIn, 3, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power2.easeOut }, 'start' );
    }


    afterReveal(done = null) {

        // this.revealTl.play(0);

        super.afterReveal(done);
    }

    update() {}


    destroy() {
        Logger.log( 'HomeController->destroy()' );
    }
}
