import { addClass, removeClass } from '../utils/CssUtils';
import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';
import Media from './media';


export default class MediaCover extends AbstractComponent {
    constructor(...args) {
        Logger.log( 'MediaCover->constructor()' );
        super(...args);

        this.classname = 'c-media-cover';
        this.initDomElements();
        this.addEventListeners();
        this.updateMediaCoverClass();
    }


    initDomElements() {
        this.$media = this.$el.querySelector( '.' + this.classname + '__media' );
        if ( this.$media ) {
            this.media = new Media( this.$media );
        }
    }


    addEventListeners() {
        if ( this.$media ) {
            this.boundResizeListener = this.app.on( 'resize', this.updateMediaCoverClass.bind( this ) );
            this.boundMediaAttributesUpdateListener = this.media.addListener( 'media-attributes-update', this.updateMediaCoverClass.bind( this) );
        }
    }


    updateMediaCoverClass() {
        if ( this.$media ) {
            const mediaCoverBoundingClientRect = this.$el.getBoundingClientRect();
            const mediaCoverWidth = mediaCoverBoundingClientRect.width;
            const mediaCoverHeight = mediaCoverBoundingClientRect.height;
            const mediaRatio = parseFloat( this.$media.getAttribute( 'data-ratio' ) );
            if ( mediaRatio ) {
                // console.log( 'mediaCoverWidth: ' + mediaCoverWidth );
                // console.log( 'mediaCoverHeight: ' + mediaCoverHeight );
                // console.log( 'mediaCoverWidth / mediaCoverHeight: ' + mediaCoverWidth / mediaCoverHeight );
                // console.log( 'mediaRatio: ' + mediaRatio );
                // console.log( '( mediaCoverWidth / mediaCoverHeight ) > mediaRatio: ' + ( mediaCoverWidth / mediaCoverHeight ) > mediaRatio );
                if ( ( mediaCoverWidth / mediaCoverHeight ) > mediaRatio ) {
                    removeClass( this.$media, this.classname + '__media--height' );
                    addClass( this.$media, this.classname + '__media--width' );
                } else {
                    removeClass( this.$media, this.classname + '__media--width' );
                    addClass( this.$media, this.classname + '__media--height' );
                }
            } else {
                addClass( this.$media, this.classname + '__media--width' );
            }
        }
    }


    destroy() {}
}