import '../utils/RequestAnimationFrame'; // Cross browser RequestAnimationFrame implementation
import { debounce } from 'lodash';
import { addClass, css, hasClass, removeClass } from '../utils/CssUtils';
import { addEventListener, preventDefault } from '../utils/EventUtils';
import {delegate, getPageWidth, hasProperty} from '../utils/JsUtils';
import EventEmitter from 'eventemitter3';
import MobileDetect from 'mobile-detect';
import DefaultController from './DefaultController';
import Logger from '../utils/Logger';
import 'svgxuse';
import components from '../components/_map';
import { TweenMax, TimelineLite, Power2, Linear, Elastic, Cubic, ScrollToPlugin, CSSPlugin } from "gsap/all";
import SiteMenu from '../components/site-menu';
import controllers from './_map';
import barba from '@barba/core';
import DefaultTransition from '../transitions/DefaultTransition';
import AnyToHomeTransition from '../transitions/AnyToHomeTransition';
import AnimationsUtils from "../utils/AnimationUtils";
import gsap from 'gsap';
gsap.registerPlugin( ScrollToPlugin );



export default class AppController extends EventEmitter {
    constructor() {
        super();
        Logger.log( 'AppController->constructor()' );

        if (history.scrollRestoration) {
            history.scrollRestoration = 'auto';
        } else {
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            }
        }

        // Handling singleton creation
        if ( !AppController.singleton ) {
            // Storing the singleton instance
            AppController.singleton = this;

            // Fields
            this.controllers = controllers;
            this.currentController = null;
            this.previousController = null;
            this.components_map = components;
            this.components = [];
            this.animation = {
                loader: {
                    duration: 1800,
                    timeout: 750
                }
            };

            // Initializing
            this.initDomElements();
            this.initViewportVhVar();
            this.initDeviceOrientationDetection();
            this.initMenu();
            this.initBarba();
            this.instantiateController( this.$body.getAttribute( 'data-controller' ), this.controllerReady.bind( this ) );
            this.instantiateComponents();
            this.initAnimations();
            this.initEventListeners();
            this.checkLoader();
            this.initLoader();
        }
       
        // Returning the singleton
        return AppController.singleton;
    }


    static getInstance() {
        return AppController.singleton;
    }

    
    initDomElements() {
        this.$html = document.getElementsByTagName( 'html' )[0];
        this.$body = document.getElementsByTagName( 'body' )[0];
        this.$main = document.getElementsByTagName( 'main' )[0];


        // Avoid BarbaJS cache conflict with session back-end re-check of time_remaining
        if( this.$body.getAttribute( 'data-time-remaining' ) ) {
            var timeOutReload = this.$body.getAttribute( 'data-time-remaining' ) * 1000;
            setTimeout( () => {
                window.scrollTo(0, 0);
                document.location.reload();
            }, timeOutReload );
        }

        // Loader
        this.$siteLoader = document.querySelector( '.c-site-loader' );
    }


    initViewportVhVar() {
        this.updateViewportVhVar();
        this.addListener( 'resize', this.updateViewportVhVar );
    }

    updateViewportVhVar() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty( '--vh', `${ vh }px` );
    }


    initDeviceOrientationDetection() {
        // Device and orientation detection fields
        this.device = 'mobile';
        this.isMobile = true;
        this.isTablet = false;
        this.isDesktop = false;
        this.orientation = 'landscape';
        this.isLandscape = true;
        this.isPortrait = false;
        this.breakpoints = {
            'xs': 0,
            'sm': 576,
            'md': 768,
            'lg': 1024,
            'xl': 1280,
            'xxl': 1600
        };
        this.breakpoint = 'xs';

        this.detectDeviceOrientationAndBreakpoint();
        this.addListener( 'resize', this.detectDeviceOrientationAndBreakpoint.bind( this ) );
    }


    detectDeviceOrientationAndBreakpoint() {
        this.detectDevice();
        this.detectOrientation();
        this.detectBreakpoint();
    }


    detectDevice() {
        removeClass( this.$html, 'mobile' );
        removeClass( this.$html, 'tablet' );
        removeClass( this.$html, 'desktop' );
        const md = new MobileDetect( window.navigator.userAgent );
        this.device = md.tablet() ? 'tablet' : ( md.mobile() ? 'mobile' : 'desktop' );
        this.isMobile = ( this.device == 'mobile' );
        this.isTablet = ( this.device == 'tablet' );
        this.isDesktop = ( this.device == 'desktop' );
        // this.isSafari = ( md.match( 'Safari' ) && !md.match( 'Chrome' ) && md.ua.indexOf( 'CriOS' ) == -1 ); // to avoid chrome IOS to be safari detected
        this.isSafari = ( md.match( 'Safari' ) && !md.match( 'Chrome' ) );
        this.isEdge = ( md.match( 'Edge' ) && md.match( 'Safari' ) && md.match( 'Chrome' ) );
        this.isIE = ( md.match( 'MSIE' ) || md.match( 'Trident' ) );
        this.isFirefox = ( md.match( 'Firefox' ) );
        addClass( this.$html, this.device );
        if( this.isEdge )
            addClass( this.$html, 'edge' );
        if( this.isSafari )
            addClass( this.$html, 'safari' );
        if( this.isFirefox )
            addClass( this.$html, 'firefox' );
        if( this.isIE )
            addClass( this.$html, 'ie' );
    }

    
    detectOrientation() {
        removeClass( this.$html, 'landscape' );
        removeClass( this.$html, 'portrait' );
        this.orientation = ( window.innerWidth > window.innerHeight ) ? 'landscape' : 'portrait';
        this.isLandscape = ( this.orientation == 'landscape' );
        this.isPortrait = ( this.orientation == 'portrait' );
        addClass( this.$html, this.orientation );
    }

    
    detectBreakpoint() {
        const previousBreakpoint = this.breakpoint;
        const newBreakpoint = this.getCurrentBreakpoint();
        if ( newBreakpoint !== previousBreakpoint ) {
            this.breakpoint = newBreakpoint;
            this.emit( 'breakpoint-update', newBreakpoint, previousBreakpoint );
        }
    }


    getCurrentBreakpoint() {
        const pageWidth = getPageWidth();
        let previousBreakpoint = null;
        for ( const breakpoint in this.breakpoints ) {
            if ( pageWidth < this.breakpoints[breakpoint] ) {
                break;
            }
            previousBreakpoint = breakpoint;
        }
        return previousBreakpoint;
    }


    getBreakpointNext( breakpoint ) {
        let breakpointFound = false;
        for ( let currentBreakpoint in this.breakpoints ) {
            if ( breakpointFound ) {
                return currentBreakpoint;
            }
            if ( currentBreakpoint == breakpoint ) {
                breakpointFound = true;
            }
        }
        return null;
    }


    getBreakpointMin( breakpoint ) {
        if ( hasProperty( this.breakpoints, breakpoint ) ) {
            return this.breakpoints[breakpoint];
        }
        return null;
    }


    getBreakpointMax( breakpoint ) {
        const nextBreakpoint = this.getBreakpointNext( breakpoint );
        if ( nextBreakpoint != null ) {
            if ( hasProperty( this.breakpoints, nextBreakpoint ) ) {
                return this.breakpoints[nextBreakpoint] - .02;
            }
        }
        else if ( hasProperty( this.breakpoints, breakpoint ) ) {
            return Infinity;
        }
        return null;
    }


    isBreakpointUp( breakpoint ) {
        const min = this.getBreakpointMin( breakpoint );
        if ( min != null ) {
            return getPageWidth() >= min;
        }
        return false;
    }


    isBreakpointDown( breakpoint ) {
        const max = this.getBreakpointMax( breakpoint );
        if ( max ) {
            return getPageWidth() < max;
        }
        return false;
    }


    isBreakpointBetween( lower, upper ) {
        const min = this.getBreakpointMin( lower );
        const max = this.getBreakpointMax( upper );
        if ( ( min != null ) && ( max != null ) ) {
            const pageWidth = getPageWidth();
            return ( pageWidth >= min ) && ( pageWidth < max );
        }
        else if ( max == null ) {
            return this.isBreakpointUp( lower );
        }
        else if ( min == null ) {
            return this.isBreakpointDown( upper );
        }
        return false;
    }


    getCurrentBreakpointValue( breakpoints ) {
        return this.getBreakpointValue( breakpoints, this.breakpoint );
    }

    
    getBreakpointValue( breakpoints, breakpoint ) {
        let currentValue = null;
        if ( breakpoints ) {
            let breakpointFound = false;
            for ( const currentBreakpoint in this.breakpoints ) {
                if ( hasProperty( breakpoints, currentBreakpoint ) ) {
                    currentValue = breakpoints[currentBreakpoint];
                }
                if ( currentBreakpoint == breakpoint ) {
                    breakpointFound = true;
                    if ( currentValue !== null ) {
                        return currentValue;
                    }
                } else {
                    if ( breakpointFound && ( currentValue !== null ) ) {
                        return currentValue;
                    }
                }
            }
        }
        return currentValue;
    }


    initMenu() {
        this.Menu = new SiteMenu( this );
    }

    
    instantiateController( namespace, callback = null ) {
        this.$body.setAttribute( 'data-controller', namespace );

        this.instantiateComponents();

        if ( hasProperty( this.controllers, namespace ) ) {
            const controller = this.controllers[namespace];

            if ( controller ) {
                this.setCurrentController( new controller.default( callback ) );
                return;
            }
        }

        this.setCurrentController( new DefaultController( callback ) );
    }


    updateBodyClassesFromContainers( currentContainer, nextContainer ) {
        if ( currentContainer ) {
            removeClass( this.$body, currentContainer.getAttribute( 'data-body-class' ) );
        }

        if ( nextContainer ) {
            const nextContainerBodyClass = nextContainer.getAttribute( 'data-body-class' );
            this.currentControllerDOMElement = nextContainer;
            // console.log('NEW APP CONTAINER IS /: ', nextContainer, '  ', nextContainerBodyClass);
            if ( nextContainerBodyClass && !hasClass( this.$body, nextContainerBodyClass ) ) {
                addClass( this.$body, nextContainerBodyClass );
            }
        }
    }


    setCurrentController( controller ) {
        this.previousController = this.currentController;
        this.currentController = controller;
    }


    getCurrentController() {
        return this.currentController;
    }


    getPreviousController() {
        return this.previousController;
    }


    clearPreviousController() {
        this.destroyPreviousController();
        this.resetPreviousController();
    }

    
    destroyPreviousController() {
        if ( this.previousController ) {
            this.destroyComponents();
            this.previousController.destroy();
        }
    }


    resetPreviousController() {
        this.previousController = null;
    }


    instantiateComponents() {
        var $mains = this.$body.querySelectorAll( 'main' );
        var page = ( $mains.length > 1 ) ? $mains[1] : $mains[0];
        let components = page.querySelectorAll( '[data-component]' );
        this.components = [];
        for ( let i = 0; i < components.length; i++ ) {
            const component = components[i];
            const name = component.getAttribute('data-component');
            if ( hasProperty( this.components_map, name ) ) {
                const componentClass = this.components_map[name];
                if ( componentClass ) {
                    this.components.push( new componentClass.default( component ) );
                }
            }
        }
    }

    destroyComponents() {
        for( let i = 0; i < this.components.length; i++ ) {
            this.components[i].destroy();
        }
    }


    initEventListeners() {
        addEventListener( window, 'resize', debounce( ( event ) => {
            this.emit( 'resize', event );
            this.resize();
        }, 16 ) );

        // ScrollTo link
        delegate( this.$body, '.js-scroll-to', 'click', (e) => {
            e.preventDefault();
            var target = this.$body.querySelector( '#' + e.delegateTarget.getAttribute( 'data-anchor' ) );
            if( target ) {
                var offset = target.offsetTop - 1.4 * this.Menu.$menu.querySelector( '.c-site-menu__top-bar' ).getBoundingClientRect().height;
                TweenMax.to( window, 1.2, { scrollTo: { y: offset, autoKill: false } , ease: Cubic.easeOut } );
            }
        });
    }


    initCustomRaf() {
        requestAnimationFrame( this.run.bind(this) );
    }


    run() {
        this.update();
        requestAnimationFrame( this.run.bind(this) );
    }


    update() {
        this.getCurrentController().update();
    }

    initLoader() {
        this._loaderTl = new TimelineLite(
            {
                paused: true,
            });

        this._loaderTl
            .add( () => {
                this.$siteLoader.classList.add( 'reveal' );
            }, 'start' )
            .add( () => {
                this.currentController.afterReveal();
            });

    }

    hideLoader( animation ) {
        setTimeout( () => {
            this._loaderTl.play();
        }, this.animation.loader.timeout );
    }


    checkLoader() {
        if ( !this.currentController.needsLoader() ) {
            css( this.$siteLoader, { display: 'none' } );
        }
    }


    controllerReady() {
        if ( this.currentController.needsLoader() ) {
            this.hideLoader();
        }
    }


    initBarba() {
        // [BARBAJS BUG FIX] Getting initial hash
        const hash = window.location.hash.replace( '#', '' );

        this.Barba = barba;
        this.Barba.init( {
            timeout: 10000,
            prevent: ( data ) => {
                if ( data.el.href == window.location.href ) {
                    data.event.preventDefault();
                    return false;
                }
            },
            requestError: (trigger, action, url, response) => {
                if (action === 'click' && response.status && response.status === 404) {
                    document.location.reload();
                }

                return false;
            },
            transitions: [
                new AnyToHomeTransition(),
                new DefaultTransition(),
            ]
        } );

        this.Barba.hooks.before( () => {
            this.Menu.updateActive();
        });

        // this.Barba.hooks.after( () => {
        // });

        // [BARBAJS BUG FIX] Re-initializing initial hash as a bug on BarbaJS removes it :(
        if ( hash && hash.length && ( 'replaceState' in history ) ) {
            history.replaceState( '', document.title, window.location.pathname + window.location.search + '#' + hash );
        }
    }

    initAnimations() {
        this.Animations = new AnimationsUtils();
    }


    resize() {
    }

}
