import AppController from '../controllers/AppController';
import Logger from '../utils/Logger';
import { TweenMax, Power2 } from "gsap/all";


export default class DefaultTransition {
    constructor() {
        Logger.log( 'DefaultTransition->constructor()' );

        this.initDOMElements();
    }

    initDOMElements() {
        // this.$pageTransition = document.body.querySelector( '.c-page-transition' );
        // this.$background = this.$pageTransition.querySelector( '.c-page-transition__background' );
    }

    beforeLeave( data ) {
        const done = this.async();
        const app = AppController.getInstance();

        Logger.log( 'DefaultTransition->beforeLeave()' );

        // Updating body class and instantiating the new controller
        app.updateBodyClassesFromContainers( null, data.next.container );

        // Leaving the current controller
        app.getCurrentController().beforeLeave( () => {
            if( app.Menu.isMenuOpen != true ) {
                TweenMax.to( data.current.container, 0.75, { autoAlpha: 0, onComplete: () => {
                    window.scrollTo(0,0);
                    done();
                } } );
            } else {
                window.scrollTo(0,0);
                done();
            }
        } );
    }


    enter( data ) {
        const done = this.async();
        const app = AppController.getInstance();

        // Hiding the current and next container
        data.current.container.style.opacity = 0;
        data.next.container.style.opacity = 0;

        app.instantiateController( data.next.namespace, () => {
            // Getting the new current controller
            const currentController = app.getCurrentController();

            // Pre-reveal hook
            currentController.beforeReveal( () => {

                // Showing again the next container
                done();

            } );
        } );
    }

    after( data ) {
        const app = AppController.getInstance();
        app.updateBodyClassesFromContainers( data.current.container );
        app.clearPreviousController();

        // Getting the new current controller
        const currentController = app.getCurrentController();

        if( app.Menu.isMenuOpen != true ) {
            if( app.Animations )
                app.Animations.refresh();
            TweenMax.set( data.next.container, { autoAlpha: 1 } );
            // Post-reveal hook
            currentController.afterReveal();
        } else {
            app.Menu.closeMenu();
            // Set delay of closing menu before changing transition
            var delay = app.Menu._openTl.duration();
            setTimeout( () => {
                if( app.Animations )
                    app.Animations.refresh();
                TweenMax.set( data.next.container, { autoAlpha: 1 } );
                // Post-reveal hook
                currentController.afterReveal();
            }, delay * 1000 - 150 );
        }
    }
}
