import { hasProperty } from './JsUtils';

export default class Logger {
    constructor() {
        // Handling singleton creation
        if ( !Logger.singleton ) {
            // Storing the singleton instance
            Logger.singleton = this;

            // Logger status
            Logger.enabled = true;
        }
    
        // Returning the singleton
        return Logger.singleton;
    }


    static debug( ...args ) {
        Logger.console( 'debug', ...args );
    }


    static log( ...args ) {
        Logger.console( 'log', ...args );
    }


    static info( ...args ) {
        Logger.console( 'info', ...args );
    }


    static warn( ...args ) {
        Logger.console( 'warn', ...args );
    }


    static error( ...args ) {
        Logger.console( 'error', ...args );
    }


    static table( ...args ) {
        Logger.console( 'table', ...args );
    }


    static trace( ...args ) {
        Logger.console( 'trace', ...args );
    }


    static console( level, ...args ) {
        if ( Logger.enabled && hasProperty ( console, level ) ) {
            console[level]( ...args );
        }
    }
}
