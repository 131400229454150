/**
* Gets an iterable element for the passed element/s.
*/
export function getList( item ) {
    return !isArrayOrList(item) ? [item] : item;
}


/**
* Checks if the passed element is an iterable element or not.
*/
export function isArrayOrList( el ) {
    return ( Object.prototype.toString.call( el ) === '[object Array]' ) ||
        ( Object.prototype.toString.call( el ) === '[object NodeList]' );
}


/**
* Creates a HTMLElement from the passed HTML string.
* https://stackoverflow.com/a/494348/1081396
*/
export function createElementFromHTML( htmlString ) {
    var div = document.createElement( 'div' );
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
}


/**
* http://stackoverflow.com/questions/22100853/dom-pure-javascript-solution-to-jquery-closest-implementation
* Returns the element or `false` if there's none.
*/
export function closest( el, selector ) {
    if ( el && ( el.nodeType === 1 ) ) {
        if ( matches ( el, selector ) ) {
            return el;
        }
        return closest( el.parentNode, selector );
    }
    return null;
}


/**
* Polyfill of .matches().
*/
function matches( el, selector ) {
    return ( el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector ).call( el, selector );
}


/**
* Gets the page height. Crossbrowser.
*/
export function getPageHeight() {
    return ( 'innerHeight' in window ) ? window.innerHeight : document.documentElement.offsetHeight;
}


/**
* Gets the page width. Crossbrowser.
*/
export function getPageWidth() {
    return ( 'innerWidth' in window ) ? window.innerWidth : document.documentElement.offsetWidth;
}


/**
* Gets the page orientation.
*/
export function getPageOrientation() {
    return ( window.innerWidth > window.innerHeight ) ? 'landscape' : 'portrait';
}


/**
* Removes the node from the DOM.
*/
export function detach( node ) {
    if ( node.parentNode ) {
        return node.parentElement.removeChild( node );
    }
    return null;
}


/**
* Attaches the node to the target in the DOM.
*/
export function attach( target, node ) {
    target.appendChild( node );
}


/**
* Catches the passed promise from throwing exception.
*/
export function catchPromiseException( promise ) {
    if ( promise ) {
        try {
            promise.then( () => { /* Does nothing */ } )
                .catch( () => { /* Silently fails */ } );
        } catch ( error ) { /* Silently fails */ }
    }
}


/**
* Converts the passed object to the equivalent boolean (handles multiple alternative forms for 'true' value like '1', 'on', 'yes').
*/
export function convertToBoolean( value ) {
    switch ( value ) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default: 
            return false;
     }
}


/**
* Similar to sprintf in non-JS languages.
*/
export function stringFormat( s ) {
    var args = Array.prototype.slice.call( arguments, 1 );
    return s.replace( /{(\d+)}/g, function( match, number ) { 
        return ( typeof args[number] != 'undefined' )
        ? args[number] 
        : match
        ;
    } );
}

/**
 * Delegates event to a selector
 *
 * @param  {Element}  element
 * @param  {string}   selector
 * @param  {string}   type
 * @param  {Function} callback
 * @param  {boolean}  useCapture
 * @return {Object}
 */
export function delegate(element, selector, type, callback, useCapture) {
    let listenerFn = listener.apply(this, arguments);
    element.addEventListener(type, listenerFn, useCapture);

    return {
        destroy: () => {
            element.removeEventListener(type, listenerFn, useCapture);
        }
    };
}

/**
 * Finds closest match and invokes callback
 *
 * @param  {Element}  element
 * @param  {string}   selector
 * @param  {string}   type
 * @param  {Function} callback
 * @return {Function}
 */
export function listener(element, selector, type, callback) {
    return event => {
        event.delegateTarget = closest(event.target, selector);

        if (event.delegateTarget) {
            callback.call(element, event);
        }
    }
}


/**
 * Checks if the object has the given property.
 */
export function hasProperty( object, property ) {
    if ( object ) {
        return Object.prototype.hasOwnProperty.call( object, property );
    }
    return false;
}


/**
 * Gets the property on the given object or null if it doesn't exist.
 */
export function getProperty( object, property ) {
    if ( hasProperty( object, property ) ) {
        return object[property];
    }
    return null;
}

/**
 * Loop on an index value
 *
 * @param  {number} index  Index
 * @param  {number} length Length
 * @return {number}        Looped index
 */
export function loopIndex(index, length) {
    if (index < 0)
        index = length + index % length;
    if (index >= length)
        return index % length;
    return index;
}

/**
 * Remove accent of string
 *
 * @return {string}       Remove accent string
 */
String.prototype.removeAccent = function () {
    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ];
    var noaccent = [ 'A','a','E','e','I','i','O','o','U','u','N','n','C','c' ];

    var str = this;
    for( var i = 0; i < accent.length; i++ ) {
        str = str.replace( accent[i], noaccent[i] );
    }

    return str;
}
