import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";
import { addEventListener, removeEventListener } from '../utils/EventUtils';


export default class ListSculptureController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );
        Logger.log( 'ListSculpture->constructor()' );

        this.initDomElements();
        this.addEventListeners();
        this.initRevealTl();

        this.ready();
    }


    initDomElements() {
        var $mains = document.body.querySelectorAll( 'main' );
        this.$page = ( $mains.length > 1 ) ? $mains[1] : $mains[0];
    }


    addEventListeners() {
    }

    initRevealTl() {
        this.revealTl = new TimelineLite( { paused : true } );
    }

    afterReveal(done = null) {

        // this.revealTl.play(0);

        super.afterReveal(done);
    }

    update() {}


    destroy() {
        Logger.log( 'ListSculpture->destroy()' );
    }
}
